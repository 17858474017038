
export default [
    {
        path: '/',
        name: 'home',
        component: () => import('../views/Dashboard.vue'),
        meta: {
            icon: 'el-icon-s-data',
            title: 'Dashboard',
            auth: true,
            role: ['admin', 'user']
        },
    },
    {
        path: '/my-events',
        name: 'my-events',
        component: () => import('../views/MyEvents.vue'),
        meta: {
            icon: 'el-icon-date',
            title: 'My Events',
            auth: true,
            role: ['admin', 'user']
        },
    },
    {
        path: '/events',
        name: 'events',
        component: () => import('../views/Events.vue'),
        meta: {
            icon: 'el-icon-tickets',
            title: 'Events Log',
            auth: true,
            role: ['admin', 'user']
        },
    },
    {
        path: '/profile',
        name: 'profile',
        component: () => import('../views/Profile.vue'),
        meta: {
            icon: 'el-icon-user',
            title: 'Profile',
            auth: true,
            role: ['admin', 'user']
        },
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/Login.vue'),
        meta: {
            title: 'Login Page',
            auth: false
        }
    }
]