import ai from '@/utils/ai'
import i18n from '@/locales'
import {camelize} from '@/utils'

export default function (param) {
    const _param = param.split('-').length > 1 ? (param.split('-')[0] + param.split('-')[1]) : param
    const toUpper = _param.toUpperCase()
    const _mutations = {
        error: `ERROR_${toUpper}`,
        load: `LOAD_${toUpper}`,
        oneLoad: `ONE_LOAD_${toUpper}`,
        pending: `PENDING_${toUpper}`,
        deleting: `DELETING_${toUpper}`,
        data: `GET_${toUpper}`,
        pagination: `PAGINATION_${toUpper}`,
        removeOne: `REMOVE_ONE_${toUpper}`,
    }
    return {
        state: {
            loading: false,
            oneLoading: false,
            pending: false,
            deleting: false,
            error: null,
            data: [],
            pagination: {
                page: 1,
                size: 10,
                total: 0,
            },
        },
        getters: {
            [camelize(`load ${_param}`)]: function (state) {
                return state.loading
            },
            [camelize(`one load ${_param}`)]: function (state) {
                return state.oneLoading
            },
            [camelize(`pending ${_param}`)]: function (state) {
                return state.pending
            },
            [camelize(`deleting ${_param}`)]: function (state) {
                return state.deleting
            },
            [camelize(`data ${_param}`)]: function (state) {
                return state.data
            },
            [camelize(`error ${_param}`)]: function (state) {
                return state.error
            },
            [camelize(`pagination ${_param}`)]: function (state) {
                return state.pagination
            },
        },
        mutations: {
            [_mutations.error]: function (state, payload) {
                state.error = payload
            },
            [_mutations.data]: function (state, payload) {
                state.data = payload
            },
            [_mutations.load]: function (state, payload) {
                state.loading = payload
            },
            [_mutations.oneLoad]: function (state, payload) {
                state.oneLoading = payload
            },
            [_mutations.pending]: function (state, payload) {
                state.pending = payload
            },
            [_mutations.deleting]: function (state, payload) {
                state.deleting = payload
            },
            [_mutations.pagination]: function (state, payload) {
                state.pagination = payload
            },
            [_mutations.removeOne]: function (state, payload) {
                state.data.splice(payload, 1)
            },
        },
        actions: {
            // get    getSomething
            [camelize(`get ${_param}`)]: function ({commit, dispatch}, params = {}) {
                commit(_mutations.load, true)
                commit(_mutations.data, [])
                return new Promise((resolve, reject) => {
                    ai
                        .get(`/${param}`, params)
                        .then((res) => {
                            commit(_mutations.pagination, {
                                page: res.meta?.page || 1,
                                size: res.meta?.pageSize || 10,
                                total: res.meta?.total,
                            })
                            commit(_mutations.error, null)
                            commit(_mutations.data, (res.data || res))
                            resolve(res)
                        })
                        .catch((error) => {
                            commit(_mutations.error, error)
                            reject(error)
                        }).finally(() => {
                        commit(_mutations.load, false)
                    })
                })
            },

            // get id   getByIdSomething
            [camelize(`get by id ${_param}`)]: function ({commit}, { id, params }) {
                commit(_mutations.oneLoad, true)
                return new Promise((resolve, reject) => {
                    ai.get(`${param}/${id}`, params)
                        .then((res) => {
                            commit(_mutations.error, null)
                            resolve(res.data)
                        })
                        .catch((error) => {
                            commit(_mutations.error, error)
                            reject(error)
                        })
                        .finally(() => {
                            commit(_mutations.oneLoad, false)
                        })
                })
            },

            // post   postSomething
            [camelize(`post ${_param}`)]: function ({commit, dispatch}, payload) {
                commit(_mutations.pending, true)
                return new Promise((resolve, reject) => {
                    ai
                        .post(`${param}`, payload)
                        .then((res) => {
                            dispatch('alert', {
                                type: 'success',
                                title: 'Success',
                                content: `${i18n.t(camelize(param))} - ${i18n.t('created successfully')}`
                            })
                            resolve(res)
                        })
                        .catch((error) => {
                            reject(error)
                        })
                        .finally(() => {
                            commit(_mutations.pending, false)
                        })
                })
            },

            // update  putSomething
            [camelize(`put ${_param}`)]: function ({commit, dispatch}, payload) {
                commit(_mutations.pending, true)
                return new Promise((resolve, reject) => {
                    ai.put(`${param}/${payload.id}`, payload)
                        .then((res) => {
                            dispatch('alert', {
                                type: 'success',
                                title: 'Success',
                                content: `${i18n.t(camelize(param))} - ${i18n.t('updated successfully')}`
                            })
                            resolve(res)
                        })
                        .catch((error) => {
                            reject(error)
                        })
                        .finally(() => {
                            commit(_mutations.pending, false)
                        })
                })
            },

            // delete   deleteSomething
            [camelize(`delete ${_param}`)]: function ({commit, dispatch}, payload) {
                commit(_mutations.deleting, true)
                return new Promise((resolve, reject) => {
                    ai.remove(`${param}/${payload}`).then((res) => {
                        dispatch('alert', {
                            type: 'success',
                            title: 'Success',
                            content: `${i18n.t(camelize(param))} - ${i18n.t('deleted successfully')}`
                        })
                        resolve(res)
                    })
                        .catch((error) => {
                            reject(error)
                        })
                        .finally(() => {
                            commit(_mutations.deleting, false)
                        })
                })
            },
        },
    }
}